<template>
  <div class="flex-grow-1">
    <ul
      class="navbar-nav navbar-expand"
      ref="navLinks"
      v-if="!$store.getters.isShowMobileMenu"
    >
      <template v-for="(menuItem, index) in headerMenu">
        <s-dropdown :key="menuItem.title" v-if="menuItem.children">
          <div class="nav-item nav-item-parent" ref="navLink">
            <span v-if="index < moreMenuIndex" class="nav-link">
              {{ menuItem.label }}
              <s-icon icon-name="arrow-bottom"></s-icon>
            </span>
          </div>
          <template v-slot:dropdown>
            <s-dropdown-menu>
              <s-dropdown-item
                v-for="childItem in menuItem.children"
                :key="childItem.label"
              >
                <router-link
                  class="nav-link text-reset"
                  :class="[
                    { 'router-link-active': getHeaderIsActive(childItem) },
                  ]"
                  :to="getHeaderLink(childItem)"
                >
                  {{ childItem.label }}
                </router-link>
              </s-dropdown-item>
            </s-dropdown-menu>
          </template>
        </s-dropdown>
        <div class="nav-item" ref="navLink" :key="menuItem.label" v-else>
          <router-link
            v-if="index < moreMenuIndex"
            :id="`nav_${$lodash.kebabCase(menuItem.label)}-link`"
            :class="{
              'nav-link': true,
              'router-link-active': getHeaderIsActive(menuItem),
            }"
            :to="getHeaderLink(menuItem)"
          >
            {{ menuItem.label }}
          </router-link>
        </div>
      </template>

      <!-- More nav link -->
      <li
        class="nav-item dropdown"
        ref="moreNav"
        v-show="moreMenuIndex < headerMenu.length"
      >
        <s-dropdown>
          <a
            class="nav-link dropdown-toggle"
            href="javascript:void(0)"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            id="nav_more-link"
          >
            More
            <s-icon icon-name="arrow-bottom"></s-icon>
          </a>
          <template v-slot:dropdown>
            <s-dropdown-menu class="more-menu">
              <template v-for="(menuItem, index) in headerMenu">
                <s-dropdown-item
                  v-if="index + 1 > moreMenuIndex"
                  :key="menuItem.label"
                >
                  <router-link
                    class="nav-link"
                    :id="`nav_${$lodash.kebabCase(menuItem.label)}-link`"
                    :class="[
                      { 'router-link-active': getHeaderIsActive(menuItem) },
                    ]"
                    :to="getHeaderLink(menuItem)"
                  >
                    {{ menuItem.label }}
                  </router-link>
                </s-dropdown-item>
              </template>
            </s-dropdown-menu>
          </template>
        </s-dropdown>
      </li>
    </ul>

    <template
      v-if="$store.getters.isShowMobileMenu && $store.state.mobileMenuStatus"
    >
      <div class="nav-menu-mask"></div>
      <transition name="menu">
        <div class="nav-menu">
          <s-list v-model="headerMenu">
            <template v-slot:list-item="itemData">
              <router-link class="menu-link" :to="getHeaderLink(itemData.item)">
                <div class="m-0 d-flex justify-content-between">
                  <div
                    :class="{
                      'theme-text-blue':
                        getNavMenuStatus(itemData.item.path) ||
                        getHeaderIsActive(itemData.item),
                    }"
                  >
                    {{ itemData.item.label }}
                  </div>
                  <s-icon
                    v-if="
                      getNavMenuStatus(itemData.item.path) ||
                      getHeaderIsActive(itemData.item)
                    "
                    icon-name="agree"
                    class="check-menu"
                    width="22"
                    height="22"
                  />
                </div>
              </router-link>
            </template>
          </s-list>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moreMenuIndex: undefined,
    }
  },
  computed: {
    menuList() {
      return this.$store?.state?.userInfo?.roleConfiguration?.menuList || []
    },
    headerMenu() {
      let headerData = this.menuList
      if (this.$store.state.userInfo.profile.roleType === 'CUSTOMER') {
        headerData = headerData.filter((menu) => {
          return menu.path !== '/application/origination'
        })
      }
      return (function getTreeData(treeHeaderData, parentId) {
        return treeHeaderData
          .map((e) => {
            let result = false
            if (e.parentMenuId + '' === parentId + '' && e.type === 'topMenu') {
              const data = {
                label: e.displayName || e.name,
                key: e.menuId,
                type: e.type,
                displayOrder: e.displayOrder,
                parentMenuId: e.parentMenuId,
                path: e.path,
                menuCode: e.menuCode,
              }
              const childrenData = getTreeData(treeHeaderData, e.menuId)
              if (childrenData.length > 0) data.children = childrenData
              result = data
            }
            return result
          })
          .filter((v) => v !== false)
          .sort(function (a, b) {
            return a.displayOrder * 1 - b.displayOrder * 1
          })
      })(headerData, 0)
    },
  },
  mounted() {
    this.initMoreMenuIndex()
  },
  methods: {
    getHeaderIsActive(headerMenu) {
      const currentPath = this.getCurrentPath(this.$route.path)
      const currentMenu = this.menuList.find((item) => {
        return (
          item.path === currentPath ||
          (item?.childPath && item.childPath.includes(currentPath))
        )
      })
      let parentMenuIds = []
      if (!this.$utils.isEmpty(currentMenu)) {
        parentMenuIds = this.getParentMenuIds(currentMenu)
      }

      return (
        headerMenu.key === currentMenu?.menuId ||
        parentMenuIds.includes(headerMenu.key) ||
        this.getParentPageCode().includes(headerMenu.menuCode)
      )
    },
    getCurrentPath(currentPath) {
      const amendmentReviewPath = '/service/amendment-review'
      const amendmentListPath = '/service/amendment-list'
      const amendmentPath = '/service/amendment'
      if (currentPath === amendmentListPath) {
        return amendmentPath
      }
      if (currentPath.includes(amendmentReviewPath)) {
        return amendmentReviewPath
      }
      return currentPath
    },
    getParentMenuIds(currentMenu, parentMenuIds = []) {
      if (currentMenu?.parentMenuId && currentMenu?.parentMenuId !== '0') {
        parentMenuIds.push(currentMenu.parentMenuId)
        const parentMenu = this.menuList.find(
          (item) => item.menuId === currentMenu.parentMenuId
        )
        return this.getParentMenuIds(parentMenu, parentMenuIds)
      } else {
        return parentMenuIds
      }
    },
    getParentPageCode() {
      const metaArr = (this.$route.matched || []).map((route) => route.meta)
      for (const meta of metaArr.reverse()) {
        if (meta.parentPageCode) {
          return meta.parentPageCode
        }
      }
      return []
    },
    getHeaderLink(headerMenu) {
      let link = headerMenu?.path
      const isFirstChildPath = 'IS_FIRST_CHILD_PATH'

      if (this.$utils.isNormalEmpty(link) || link === isFirstChildPath) {
        let children = this.menuList.filter(
          (item) => item.parentMenuId === headerMenu.key && item.path
        )
        if (!this.$utils.isEmpty(children)) {
          children = this.$lodash.orderBy(children, ['displayOrder'], ['asc'])
          link = children[0]?.path
        }
      }

      return link
    },
    initMoreMenuIndex() {
      this.moreMenuIndex = this.headerMenu && this.headerMenu.length
    },
    // auto show and show menu item
    handleMenuWidthOut() {
      if (!this.$refs.navLink || !this.$refs.navLinks) return
      this.deepWidthOutIndex(this.headerMenu && this.headerMenu.length)
    },

    isWidthOut() {
      const menuItemsLength = this.$refs.navLink.map((val) => val.offsetWidth)
      const menuItems = menuItemsLength.filter((_val, index) => {
        return index < this.moreMenuIndex
      })
      const moreNavWidth =
        this.headerMenu.length === this.moreMenuIndex ? 0 : 100 // 100 is the width of the more drop-down option
      const navLinksPadding = 4
      const isNavContentWidthWidthOut =
        this.$lodash.sum(menuItems) + moreNavWidth + navLinksPadding >=
        this.$refs.navLinks.clientWidth

      const profileWidth = this.$parent.$refs.profile.clientWidth
      const headerWidth = this.$parent.$refs['header-navbar'].clientWidth
      const logoImgWidth = this.$parent.$refs.logoImg.clientWidth
      const headerPadding = 32
      const linkWrapWidth =
        headerWidth - profileWidth - logoImgWidth - headerPadding
      const isHeaderWidthOut = this.$refs.navLinks.clientWidth >= linkWrapWidth

      return isNavContentWidthWidthOut || isHeaderWidthOut
    },

    deepWidthOutIndex(menuLength) {
      if (window.innerWidth < 992) {
        this.initMoreMenuIndex()
        return
      }
      if (this.isWidthOut()) {
        if (this.moreMenuIndex >= 0) {
          this.moreMenuIndex--
          this.$nextTick(() => {
            if (this.isWidthOut()) this.deepWidthOutIndex()
          })
        }
      } else {
        if (this.moreMenuIndex < menuLength) {
          this.moreMenuIndex++
          this.$nextTick(() => {
            this.isWidthOut() ? this.moreMenuIndex-- : this.deepWidthOutIndex()
          })
        }
      }
    },

    getNavMenuStatus(path) {
      return path === this.$route.path
    },
  },
}
</script>

<style lang="scss" scoped>
@import './nav.scss';
</style>
